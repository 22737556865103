carouselJs = {

  initCarousel: function () {
    let feed = new Swiper('.swiper-container', {
      autoplay: {
        delay: 4000
      },
      loop: true,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        950: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1224: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1424: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },

    });

  },

  run: function () {
    carouselJs.initCarousel();
  }

}

$(document).ready(function () {

  $window = $(window);
  $body = $('body');


  carouselJs.run();


});
