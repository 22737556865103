 app = {

  initLoader: function () {
    setTimeout(function () {

      $body.find('.js-page-loader').addClass('page-load-loaded');

    }, 100);
  },

  initHamburger: function () {
    $body.find('.hamburger').on('click', function () {
      $(this).toggleClass('is-active');
      $body.find('#mobile-menu').toggleClass('xs-hide');
      $body.toggleClass('md-overflow-hidden');
    });

  },
  initModal: function () {
    //show modal
    $body.find('.js-trigger-modal').on('click', function () {
      $(this).parent().addClass('js-show-modal');
    });

    //hide modal if click on X
    $body.find('.js-hide-modal').on('click', function () {
      $body.find('.js-modal').removeClass('js-show-modal');
    });

    //hide modal if click outside of modal
    $('.modal').click(function () {
      $body.find('.js-modal').removeClass('js-show-modal');
    });

    //hide modal if click outside of modal
    $('.modal__content').click(function (event) {
      event.stopPropagation();
    });
  },

  run: function () {
    // app.initLoader();
    app.initHamburger();
    app.initModal();
  }
}

$(document).ready(function () {

  $window = $(window);
  $body = $('body');

  app.run();

});






