/*
    application js. hamburger menu
*/
checkoutJs = {

    initAisles : function()
    {
      
        $body.find('.js-memberAisle-toggle').on('click', function(){
            $body.find('.js-memberAisle').toggleClass('hidden');
            $(this).toggleClass('aisleActive');
        });
         $body.find('.js-workshopAisle-toggle').on('click', function(){
            $body.find('.js-workshopAisle').toggleClass('hidden');
            $(this).toggleClass('aisleActive');    
        });
          $body.find('.js-donateAisle-toggle').on('click', function(){
            $body.find('.js-donateAisle').toggleClass('hidden');
            $(this).toggleClass('aisleActive');     
        });
         $body.find('.js-specialEventAisle-toggle').on('click', function(){
            $body.find('.js-specialEventsAisle').toggleClass('hidden');
            $(this).toggleClass('aisleActive');    
        });
    },

    run : function()
    {    
        checkoutJs.initAisles();

    }
}

$(document).ready(function(){

    $window       = $(window);
    $body         = $('body');

    $window.keydown(function(event){
      if(event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });

    
    checkoutJs.run();



});